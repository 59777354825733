@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--font-family-1: "Retro Computer";
	--font-family-2: "Pixellari";
	--font-family-3: "Minecraft";
}

@font-face {
	font-family: "Retro Computer";
	src: url(./assets/fonts/retro_computer_personal_use.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Pixellari";
	src: url(./assets/fonts/Pixellari.ttf);
	font-display: swap;
}

@font-face {
	font-family: "Minecraft";
	src: url(./assets/fonts/minecraft_font.ttf);
	font-display: swap;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: "Pixellari" !important;
	overflow-x: hidden;
	background: url("./assets/img/background.svg");
	width: 100%;
	position: relative;
}

a {
	color: #fff !important;
}

a:hover {
	text-decoration: underline white !important;
}

::-webkit-scrollbar {
	width: 0.4rem;
	height: 0rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: #fff;
}

/* Track */
::-webkit-scrollbar-track {
	background: #000;
}

.max-width {
	max-width: 1440px;
	margin: auto;
}
