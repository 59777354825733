.open-nav {
  position: absolute;
  background: #282828;
  height: 100vh;
  z-index: +100;
  width: 70vw;
  right: 0;
  transition: 0.4s ease-out;
}
.close-nav {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  right: 0;
  transition: 0.4s ease-in;
}
.open-nav a {
  margin-top: 1rem;
}
