.stakelist-rows {
  background: #2c2c2c;
  border-radius: 10px;
}
.open {
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
.close {
  height: 0;
  width: 0;
  overflow: hidden;
  /* transform: scale(0); */
  transition: 0.3s;
}
