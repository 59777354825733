.stakeRow {
    background-color: transparent;
}

.stakeRow:hover {

    background-color: #224;
    cursor: pointer;
}

.stakeButton {

    background-color: transparent;
    color:transparent;
    border: none;
    min-width: 120px;

}

.stakeButton:hover{

    background-color: transparent;
    color: transparent;

}

.ant-btn:hover, .ant-btn:focus, .ant-btn:active .ant-btn::after{

    background-color: transparent;
    color: transparent;

}